<template>
    <el-upload
    class="upload-image"
    :action="action"
    :show-file-list="false"
    :on-success="SuccessUpload"
    :before-upload="BeforeUpload"
    :headers="headers"
    accept=".png"
    v-loading="loading"
  >
   <div class="upload-image-bd">
        <img v-if="imageUrl" :src="`${imageUrl}`" class="upload-image-el" />
        <el-icon v-else class="upload-uploader-icon"><Plus /></el-icon>
   </div>
  </el-upload>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import {inject, ref} from 'vue'
import cookies from 'vue-cookies'

export default {
    components: {Plus},
    emits: ['success'],
    props: {
        defaultValue: {
            type: String,
            default: ""
        },
        size: {
            type: Number,
            default: 0.5
        }
    },
    setup(props, ctx) {
        const action = `${process.env.NODE_ENV === 'development' ? '/api/' : process.env.VUE_APP_BASE_API}upload`
        let imageUrl = ref(null)
        let loading = ref(false)
        let headers = ref({identifier: cookies.get('ADMIN_TOKEN')})
        const elplus = inject('elplus')
        // process.env.VUE_APP_BASE_IMAGE_URL
        const methods = {
            SelectedImage (url) {
                console.log(url)
                imageUrl.value = url ? `${process.env.VUE_APP_BASE_IMAGE_URL}${url}` : ''
            },
            SuccessUpload (res) {
                loading.value = false
                imageUrl.value = `${process.env.VUE_APP_BASE_IMAGE_URL}${res.data}`
                ctx.emit('success', res)
            },
            BeforeUpload (file) {
                if (file.size / (1024 * 1024) > props.size) {  // 限制文件大小
                    elplus.message({message: `当前限制文件大小不能大于1M`, type: "warning"})
                    return false
                }
                loading.value = true
            }
        }
        return {
            loading,
            imageUrl,
            action,
            headers,
            ...methods
        }
    },
}
</script>

<style lang="scss" scoped>
.upload-image{
    .upload-image-bd{
        width: 150px;
        height: 150px;
        border: 1px dashed #606266;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    cursor: pointer;
    .upload-image-el{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .el-icon {
        font-size: 36px;
        color: #606266;
    }
}
</style>